<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->

    <div class="container mt-6">
        <div>

            <div class="search-field">
            <v-text-field dense @keydown.enter="searchUser" v-model="search" outlined placeholder="search user" hint="enter email or name or mobile">
                <template v-slot:append>
                   <fai icon="search"/>
                </template>
            </v-text-field>

          
            <v-select
              :items="userfilter"
              label="Outlined style"
              dense
              outlined
              v-model="selectfilter"
            >
            <template v-slot:append>
                   <fai icon="user-group"/>
                </template>
            </v-select>
            
            <div class="statbox" id="statbox1">
            <div class="caption">Total Customers</div>
            <div><h4>{{totalc}}</h4></div>
            </div>

            <div class="statbox" id="statbox2">
            <div class="caption">Total Vendors</div>
            <div><h4>{{totalv}}</h4></div>
            </div>

            </div>

            </div>
            <v-progress-linear coloe="primary" v-if="load"></v-progress-linear>
             <v-data-table class="" :headers="headers" :items="items">
            <template v-slot:[`item.status`]="{ item }">
                <v-chip v-if="item.status===1" color="#4caf50" dark>Verified</v-chip>
                <v-chip v-if="item.status!==1" color="#dd2c00" dark>Unverified</v-chip>
            </template>

            <template v-slot:[`item.action`]="{item }">
                <v-btn :color="fill" fab icon class="action_btn" small @click="showUserInfo(item.id)"><fai icon="ellipsis-v"/></v-btn>
                <v-btn :color="fill" fab icon class="action_btn" small @click.stop="showDeleteModal(item.id)"><fai icon="trash-can"/></v-btn>
            </template>
        </v-data-table>
       <!-- <div class="mt-2 d-flex justify-center">
          <v-btn @click.stop="loadPrevious" class="mx-1" small rounded>Previous</v-btn>
           <v-btn @click.stop="loadNext" class="mx-1" small rounded>Next</v-btn>
        </div>-->
         <div class="text-center">
          <v-pagination
            v-model="current_page"
            :length="last_page"
            :total-visible="7"
          ></v-pagination>
        </div>
        <!---
        -->
        
        <!---
        -->
    </div>

    <!---
        DELETE PROMPT FOR CATEGORIES
        -->
        <v-dialog
        transition="dialog-bottom-transition"
        persistent
        max-width="250"
        v-model="delete_prompt"
        >
            <v-card class="pa-2 prompt pt-6">
                Do you want to permanently delete this user's account and products?
                <div v-if="delete_progress" class="d-flex justify-center">
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
                </div>
                <v-card-actions class="d-flex justify-space-between px-3 mt-4">
                <v-btn dark color="green" @click.stop="delete_prompt=!delete_prompt">No</v-btn>
                <v-btn dark color="red" @click.stop="removeUser()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!---
        -->

      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
          delete_prompt:false,
          delete_index:undefined,
          delete_progress:false,
          userfilter:["Customers","Vendors"],
          selectfilter:"Customers",
          load:false,
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
               
            ],
            search:'',
            /**
             * TEST DATA
             */
            current_page:1,
            total_users:0,
            last_page:1,
            fill:'#f44336',
            test_image:require("../assets/1.jpg"),
            info_name:"Success",
            info_mobile:"08032456789",
            info_email:"sontech@gmail.com",
            address:'No 6 oasis street',
            info_gender:"Male",
            info_image:'',
            info_status:1,
            info_id:undefined,
            info_nin:undefined,
            info_nin_status:undefined,
            totalc:0,
            totalv:0,
            /**
             * END OF TEST DATA
             */
            user_info_box:false,
            headers:[
                {text:"Firstname" , value:"firstname"},
                {text:"Lastname" , value:"lastname"},
                {text:"Address" , value:"address"},
                {text:"Phone" , value:"phone"},
                {text:"Email" , value:"email"},
                {text:"Wallet ID" , value:"wallet_id"},
                {text:"Verified" , value:"status"},
                {text:"Action" , value:"action"},
            ],
            items:[
                {
                    firstname:"success",
                    lastname:"onyegbanokwu",
                    address:'',
                    phone:'09034567890',
                    email:'sonobktech@gmail.com',
                    wallet_id:'3100234567',
                    status:1,
                    id:1,
                    profile_picture:'',
                    nin:'67898765434',
                }
            ],
        }
    },
    components:{
  
    },
    methods:{
    removeUser(){
      this.delete_progress=true;
       this.axios({
          url:this.$store.getters.get_api+'deleteUser/'+this.items[this.delete_index].email,
          method:'get',
          headers:{
          "Access-Control-Allow-Origin":"*",
          'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((res)=>{
                if(!res.data.error){
                  this.delete_prompt=false;
                  this.delete_progress=false;
                  this.items.splice(this.delete_index,1);
                }
            }).catch((error)=>{
                throw error;
            })
    },
    showDeleteModal(id){
      for(let i=0; i<this.items.length; i++){
        if(this.items[i].id==id){
          this.delete_index = i;
          break;
        }
      }
      this.delete_prompt=true;
    },
    searchUser(){
      this.axios({
                url:this.$store.getters.get_api+'searchUser/'+this.search,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((res)=>{
                const data = res.data
                
                if(!data.error){
                    this.items=new Array();
                    this.items=data.message
                 //   console.log(this.items)
                }
            }).catch((error)=>{
                return error
            })
    },
  /*  loadPrevious(){
          if(this.current_page>1){
              this.current_page -=1;
              this.getAllUsers(this.current_page);
          }
      },
      loadNext(){
          if(this.current_page<this.last_page){
              this.current_page +=1;
              this.getAllUsers(this.current_page);
          }
      },*/
     wrongNiNStatus(){
            this.ninWrongLoader='success';
            this.axios({
                url:this.$store.getters.get_api+'wrongNiNStatus/'+this.info_id,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((res)=>{
                const data = res.data
                if(!data.error){
                    this.ninWrongLoader=undefined;
                }
            }).catch((error)=>{
                return error
            })
        },
        updateNiNStatus(){
            this.ninStatusLoader='success';
            this.axios({
                url:this.$store.getters.get_api+'toggleNiNStatus/'+this.info_id,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((res)=>{
                const data = res.data
                if(!data.error){
                    this.ninStatusLoader=undefined;
                }
            }).catch((error)=>{
                return error
            })
        },
        updateAccountStatus(){
            this.statusLoader='success';
            this.axios({
                url:this.$store.getters.get_api+'toggleAccountStatus/'+this.info_id,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((res)=>{
                const data = res.data
                if(!data.error){
                    this.statusLoader=undefined;
                }
            }).catch((error)=>{
                return error
            })
        },
        showUserInfo(user_id){
            this.$router.push('userData/'+user_id)
        },
        getAllUsers(page){
            this.$store.dispatch('openProgress');
            this.axios({
              url:`${this.$store.getters.get_api}fetchUsers/${this.selectfilter}/${page}`,
              method:"get",
              headers:{
                "Access-Control-Allow-Origin":"*",
                'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((response)=>{
               this.$store.dispatch('closeProgress');
                const data = response.data;
             //  console.log(data)
                if(!data.error){
                   this.items = data.message.data
                   this.total_users = data.message.meta.total
                   this.last_page = data.message.meta.last_page
                   this.totalc = data.totalcustomers;
                   this.totalv = data.totalvendors;
                }else{
                   this.$store.commit('progress',false);
                   this.$store.commit('error',true);
                   this.$store.commit('success',false);
                   this.$store.commit('message','Error loading users');
                }
            }).catch((error)=>{
                return error
            })
        },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
     
     logout(){
      
      this.axios({
        url:this.$store.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.commit("set_token" , undefined);
          this.$router.push("/")
        }
      }).catch((error)=>{
        return error
      })
     }
    },
    created(){

    },
    mounted(){
      this.sideMenu=this.$store.getters.get_menu;
      this.getAllUsers(this.current_page)
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
       current_page(value){
         this.getAllUsers(value);
       },
       selectfilter(){
         this.getAllUsers(this.current_page);
       }
        //END OF WATCH
    }
}
</script>
<style scoped>
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
body{
    width:100%;
    height:100%;
    background:#e0e0e0;
}
.search-field{
   display:grid;
   margin-bottom:.5rem;
}

@media screen and (max-width:600px) {
    .search-field{
       grid-template-rows:auto;
       grid-template-columns:auto;
       grid-row-gap:.3rem;
    }
}
@media screen and (min-width:640px) {
    .search-field{
       grid-template-rows:auto;
       grid-template-columns:20% 20% 20% 20%;
       grid-row-gap:.3rem;
       justify-content:space-between;
       align-items:center;
    }
}
.cardx{
    position:'relative';
}
.container{
    min-width:100%;
    min-height:100%;
}
.close{
    position:absolute;
    right:2px;
    top:2px;
}
.sh{
    box-shadow:  2px 1px 2px rgba(255,255,255,.3),
             -2px -1px 2px rgba(0,0,0,.5);
    color:#ffffff;
    margin:2px;
    font-family:sans-serif;
    font-weight:bold;

}
.cardx{

}
.prompt{
  font-weight:bold;
  text-align:center;
}
.statbox{
  height:100px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-flow:column;
  color:white;
  border-radius: 6px;
  font-family:Open Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}
#statbox1{
  background:linear-gradient(45deg , #ff5252 , #ff6f00);
}
#statbox2{
  background:linear-gradient(45deg , #0277bd , #01579b);
}
</style>